import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
import {
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from './App';
import HomeTest from './Mainpages/HomeTest';
import A1A2Elementary from './Mainpages/a1-a2-elementary';
import B1B2Intermediate from './Mainpages/b1-b2-intermediate';
import C1Advanced from './Mainpages/c1-advanced';
import GrammarExercises from './Mainpages/Grammar-Exercises/grammar-exercises';
import ToBe from './Mainpages/Grammar-Exercises/to-be';
import SimplePresent from './Mainpages/Grammar-Exercises/simple-present';
import PresentContinuous from './Mainpages/Grammar-Exercises/present-continuous';
import Future from './Mainpages/Grammar-Exercises/future';
import SimplePast from './Mainpages/Grammar-Exercises/simple-past';
import FirstConditional from './Mainpages/Grammar-Exercises/first-conditional';
import SecondConditional from './Mainpages/Grammar-Exercises/second-conditional';
import ThirdConditional from './Mainpages/Grammar-Exercises/third-conditional';
import PresentPerfect from './Mainpages/Grammar-Exercises/present-perfect';
import PerfectPast from './Mainpages/Grammar-Exercises/perfect-past';
import ReadingExercises from './Mainpages/reading-exercises';
import ParagraphHeadings from './Mainpages/Reading-Exercises/paragraph-headings';
import ReadingComprehension from './Mainpages/Reading-Exercises/reading-comprehension';
import SpeakingExercises from './Mainpages/speaking-exercises';
import PictureComparison from './Mainpages/Speaking-Exercises/picture-comparison';
import Speakingtopics from './Mainpages/Speaking-Exercises/speaking-topics';
import ListeningExercises from './Mainpages/listening-exercises';
import Videos from './Mainpages/Listening-Exercises/Videos';
import Audio from './Mainpages/Listening-Exercises/Audio';
import WritingExercises from './Mainpages/writing-exercises';
import Writing1 from './Mainpages/Writing-Exercises/Writing1';
import VocabularyExercises from './Mainpages/vocabulary-exercises';
import C1VerbFlashcards from './Mainpages/Flashcards/c1-verb-flashcards';
import B2VerbFlashcards from './Mainpages/Flashcards/b2-verb-flashcards';
import B2AdjFlashcards from './Mainpages/Flashcards/b2-adj-flashcards';
import Flashcards from './Mainpages/flashcards';
import VocabularyQuizzes from './Mainpages/Quizzes/vocabulary-quizzes';
import Collocations1 from './Mainpages/Vocabulary-Exercises/Collocations1';
import WordForms from './Mainpages/Vocabulary-Exercises/word-forms';
import AllExercises from './Mainpages/all-exercises';
import Tutor from './Mainpages/Tutor';
import Teachers from './Mainpages/Teachers';
import Topics from './Mainpages/topics';
import EnglishLevelTest from './Mainpages/Level-Test/level-test';
import exambooks from './Images/exam-books.webp'
import Sidebar from './Components/Sidebar';
import Aboutexams from './Mainpages/about-exams';
import Money from './Mainpages/Topics/money';
import grammar from './Images/grammar4-1280x470.jpg'
import reading from './Images/reading1-1280x470.jpg'
import speaking from './Images/speaking3-1280x470.jpg'
import listening from './Images/listening-conference-1280x470.jpg'
import vocabulary from './Images/vocabulary-focus-1280x470.jpg'
import writing from './Images/writing1-1280x470.jpg'
import flashcards from './Images/flashcard2-1280x470.jpg'
import quizzes from './Images/quizzes1-1280x470.jpg'
import Passive from './Mainpages/Grammar-Exercises/passive';
import Simpcont from './Mainpages/Grammar-Exercises/simpcont';
import LinkingWords from './Mainpages/Vocabulary-Exercises/linking-words';
import ReportedSpeech from './Mainpages/Vocabulary-Exercises/reported-speech';
import ModalsObligation from './Mainpages/Grammar-Exercises/modals-obligation';



const Welcome = () => (
  
  <div>
          <div className="home-container">
           
           
            <div className="homebodytext">
              
        <img src={exambooks} alt="English Exam Exercises" />
              <h2>Welcome to <strong>English Exam Exercises</strong>!</h2>

              <p>Are you preparing for exams and looking for effective study resources? Look no further!  Our site offers a wide range of practice exercises, carefully organized by levels and skills to ensure you find exactly what you need. The exercises cover the most common types of tasks you will encounter on TOEFL, EuroExams, Cambridge, Oxford and graduation exams. I have put together a bunch of exercises from grammar and vocabulary to reading comprehension and writing, and these exercises are sure to help you improve for your tests.</p>

              <p>But that's not all! Our primary goal is to provide you with the best one-on-one online speaking practice. Whether you want to boost your general English proficiency or prepare specifically for an exam, we're here to help you succeed. Explore our resources, and don't hesitate to reach out to book a personalized session.</p>
              
              
              
  <div class="courses_container">
  <div class="courses_box">
    
    <div className="blog-card">
    <div className="meta">
      <div className="photo" style={{ backgroundImage: `url(${grammar})` }}></div>
      </div>
    <div className="description">
      <h1>Grammar</h1>
      <h2>Getting the basics down</h2>
      <p> Grammar exercises including all the grammar points needed per level.</p>
      <p className="read-more">
        <Link to="/grammar-exercises">Start Learning</Link>
      </p>
    </div>
  </div>
  
  <div className="blog-card alt">
    <div className="meta">
      <div className="photo" style={{ backgroundImage: `url(${vocabulary})` }}></div>
      
    </div>
    <div className="description">
      <h1>Vocabulary</h1>
      <h2>Having an adeuate vocabulary brings greater success.</h2>
      <p>Vocabulary exercises, flashcards and quizzes to help you improve and have greater success on exams.</p>
      <p className="read-more">
        <Link to="/vocabulary-exercises">Start Learning</Link>
      </p>
    </div>
  </div>

  <div className="blog-card">
    <div className="meta">
      <div className="photo" style={{ backgroundImage: `url(${speaking})` }}></div>
      
    </div>
    <div className="description">
      <h1>Speaking</h1>
      <h2>Getting the basics down</h2>
      <p> Grammar exercises including all the grammar points needed per level.</p>
      <p className="read-more">
        <Link to="/speaking-exercises">Start Learning</Link>
      </p>
    </div>
  </div>
  
  <div className="blog-card alt">
    <div className="meta">
      <div className="photo" style={{ backgroundImage: `url(${reading})` }}></div>
      
    </div>
    <div className="description">
      <h1>Reading</h1>
      <h2>Having an adeuate vocabulary brings greater success.</h2>
      <p>Vocabulary exercises, flashcards and quizzes to help you improve and have greater success on exams.</p>
      <p className="read-more">
        <Link to="/reading-exercises">Start Learning</Link>
      </p>
    </div>
  </div>

  <div className="blog-card">
    <div className="meta">
      <div className="photo" style={{ backgroundImage: `url(${writing})` }}></div>
     
    </div>
    <div className="description">
      <h1>Writing</h1>
      <h2>Getting the basics down</h2>
      <p> Grammar exercises including all the grammar points needed per level.</p>
      <p className="read-more">
        <Link to="/writing-exercises">Start Learning</Link>
      </p>
    </div>
  </div>
  
  <div className="blog-card alt">
    <div className="meta">
      <div className="photo" style={{ backgroundImage: `url(${listening})` }}></div>
      
    </div>
    <div className="description">
      <h1>Listening</h1>
      <h2>Having an adeuate vocabulary brings greater success.</h2>
      <p>Vocabulary exercises, flashcards and quizzes to help you improve and have greater success on exams.</p>
      <p className="read-more">
        <Link to="/listening-exercises">Start Learning</Link>
      </p>
    </div>
  </div>

<div className="blog-card">
  <div className="meta">
    <div className="photo" style={{ backgroundImage: `url(${flashcards})` }}></div>
    
  </div>
  <div className="description">
    <h1>Flashcards</h1>
    <h2>Getting the basics down</h2>
    <p> Grammar exercises including all the grammar points needed per level.</p>
    <p className="read-more">
      <Link to="/flashcards">Start Learning</Link>
    </p>
  </div>
</div>

<div className="blog-card alt">
  <div className="meta">
    <div className="photo" style={{ backgroundImage: `url(${quizzes})` }}></div>
    
  </div>
  <div className="description">
    <h1>Quizzes</h1>
    <h2>Having an adequate vocabulary brings greater success.</h2>
    <p>Vocabulary exercises, flashcards and quizzes to help you improve and have greater success on exams.</p>
    <p className="read-more">
      <Link to="/quizzes/vocabulary-quizzes">Start Learning</Link>
    </p>
  </div>
</div>

  </div>
</div>
          </div>
                
              <div>

             <div><Sidebar/></div>
      </div> 
              </div> 
  </div>
 
);


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <HomeTest />,
        children: [
          {
            index: true, 
            element: <Welcome />,
          },
          {
            path: 'tutor',
            element: <Tutor />,
          },
          {
            path: 'teachers',
            element: <Teachers />,
          },
          {
            path: 'about-exams',
            element: <Aboutexams />,
          },
          {
            path: 'level-test/level-test',
            element: <EnglishLevelTest />,
          },
          {
            path: 'a1-a2-elementary',
            element: <A1A2Elementary />,
          },
          {
            path: 'b1-b2-intermediate',
            element: <B1B2Intermediate />,
          },
          {
            path: 'c1-advanced',
            element: <C1Advanced />,
          },
          {
            path: 'topics',
            element: <Topics />,
          },
          
          {
            path: 'topics/money',
            element: <Money />,
          },
          {
            path: 'grammar-exercises',
            element: <GrammarExercises />,
          },
          {
            path: 'grammar-exercises/to-be',
            element: <ToBe />,
          },
          {
            path: 'grammar-exercises/simple-present',
            element: <SimplePresent />,
          },
          {
            path: 'grammar-exercises/present-continuous',
            element: <PresentContinuous />,
          },
          {
            path: 'grammar-exercises/simpcont',
            element: <Simpcont />,
          },
          {
            path: 'grammar-exercises/future',
            element: <Future />,
          },
          {
            path: 'grammar-exercises/simple-past',
            element: <SimplePast />,
          },
          {
            path: 'grammar-exercises/present-perfect',
            element: <PresentPerfect />,
          },
          {
            path: 'grammar-exercises/perfect-past',
            element: <PerfectPast />,
          },
          {
            path: 'grammar-exercises/first-conditional',
            element: <FirstConditional />,
          },
          {
            path: 'grammar-exercises/second-conditional',
            element: <SecondConditional />,
          },
          {
            path: 'grammar-exercises/third-conditional',
            element: <ThirdConditional />,
          },
          {
            path: 'grammar-exercises/passive',
            element: <Passive />,
          },
          {
            path: 'grammar-exercises/modals-obligation',
            element: <ModalsObligation />,
          },
          {
            path: 'reading-exercises',
            element: <ReadingExercises />,
          },
          {
            path: 'reading-exercises/reading-comprehension',
            element: <ReadingComprehension />,
          },
          {
            path: 'reading-exercises/paragraph-headings',
            element: <ParagraphHeadings />,
          },
          {
            path: 'writing-exercises',
            element: <WritingExercises />,
          },
          {
            path: 'writing-exercises/writing1',
            element: <Writing1 />,
          },
          {
            path: 'listening-exercises',
            element: <ListeningExercises />,
          },
          {
            path: 'listening-exercises/videos',
            element: <Videos />,
          },
          {
            path: 'listening-exercises/audio',
            element: <Audio />,
          },
          {
            path: 'vocabulary-exercises',
            element: <VocabularyExercises />,
          },
          {
            path: 'vocabulary-exercises/collocations1',
            element: <Collocations1 />,
          },
          {
            path: 'vocabulary-exercises/word-forms',
            element: <WordForms />,
          },
          {
            path: 'vocabulary-exercises/linking-words',
            element: <LinkingWords />,
          },
          {
            path: 'vocabulary-exercises/reported-speech',
            element: <ReportedSpeech />,
          },
          {
            path: 'flashcards',
            element: <Flashcards />,
          },
          {
            path: 'flashcards/c1-verb-flashcards',
            element: <C1VerbFlashcards />,
          },
          {
            path: 'flashcards/b2-verb-flashcards',
            element: <B2VerbFlashcards />,
          },
          {
            path: 'flashcards/b2-adj-flashcards',
            element: <B2AdjFlashcards />,
          },
          {
            path: 'quizzes/vocabulary-quizzes',
            element: <VocabularyQuizzes />,
          },
          {
            path: 'speaking-exercises',
            element: <SpeakingExercises />,
          },
          {
            path: 'speaking-exercises/speaking-topics',
            element: <Speakingtopics />,
          },
          {
            path: 'speaking-exercises/picture-comparison',
            element: <PictureComparison />,
          },
          {
            path: 'all-exercises',
            element: <AllExercises />,
          },
        ],
      },
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
);
